import React, { useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Tooltip from 'react-simple-tooltip';
import LanguageDropdown from './Dropdown/LanguageDropdown';
import { USER_LOGOUT } from '../actions/AuthActions';
import {
  checkLoggedInUserPermissions,
  useOutsideAlerter,
} from '../utils/Helper';
import TerminalsDropdown from './Dropdown/TerminalsDropdown';
import TagListDropdown from './Dropdown/TagListDropdown';
import DropdownComponent from './Menu/Dropdown.component';
import Notification from './Notifications/Notification';
import Logo from '../assets/svg/Logo';
import { SET_ADMIN_VIEW } from '../actions/Admin/AdminActions';
import UserIcon from '../assets/svg/UserIcon';
import HelpIcon from '../assets/svg/HelpIcon';
import { GF_DARK_BLUE, GF_PERIWINKLE } from '../utils/Constants';
import { SettingIcon } from '../assets/svg/SettingIcon';
import UserSettings from './Settings/UserSettings';
import { RESET_APP_COUNT } from '../actions/CommonActions';

export default function AppBar({ navigation }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [openUserSettings, setOpenUserSettings] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    setIsOn(false);
  });

  const {
    isAuthenticated,
    user,
    hasAdminPrivilege,
    receivedLoggedInUserTagPermissions,
  } = useSelector((state) => state.auth);
  const { loadingTerminals, terminals } = useSelector(
    (state) => state.terminal,
  );
  const { adminView } = useSelector((state) => state.admin);
  const { selectedLanguage } = useSelector((state) => state.language);

  const handleLogin = () => {
    dispatch({ type: USER_LOGOUT, payload: history.location.pathname });

    window.location.assign(`${window?.appConfig.REACT_APP_AUTH_SERVER_PATH}`);
  };

  const handleLogout = () => {
    dispatch({ type: USER_LOGOUT, payload: history.location.pathname });
    const logoutUrl =
      localStorage.getItem('logoutUrl') ??
      `${window?.appConfig.REACT_APP_AUTH_SERVER_PATH}`;
    window.location.assign(logoutUrl);
  };

  const [isOn, setIsOn] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);

  const navigate = (e, link) => {
    e.preventDefault();
    setMobileMenu(false);
    history.push(link);
  };

  const hasPermission = (menuPermission, alreadyCheckedPermission = false) => {
    let visible = false;
    if (!alreadyCheckedPermission && !menuPermission.guard_name) {
      return hasPermissionParent(menuPermission, true);
    }

    const hasUserPermissions = (receivedLoggedInUserTagPermission) => {
      if (Array.isArray(menuPermission.guard_name)) {
        return menuPermission.guard_name.includes(
          receivedLoggedInUserTagPermission,
        );
      }
      return menuPermission.guard_name === receivedLoggedInUserTagPermission;
    };

    if (receivedLoggedInUserTagPermissions !== null) {
      for (let i = 0; i < receivedLoggedInUserTagPermissions.length; i++) {
        for (
          let k = 0;
          k < receivedLoggedInUserTagPermissions[i].list.length;
          k++
        ) {
          if (
            hasUserPermissions(
              receivedLoggedInUserTagPermissions[i].list[k].guard_name,
            )
          ) {
            return true;
          }
        }
      }
    }

    return false;
  };
  const hasPermissionParent = (parent, alreadyCheckedPermission = false) => {
    return parent?.routes
      ? !!parent.routes?.find((child) => hasPermission(child))
      : hasPermission(parent, alreadyCheckedPermission);
  };

  const goToMainPage = () => {
    if (isAuthenticated && checkLoggedInUserPermissions('load-card')) {
      dispatch({ type: SET_ADMIN_VIEW, payload: false });
      history.push('/card/load-card');
    } else {
      history.push('/');
    }
  };

  const goToAdminPage = () => {
    dispatch({ type: RESET_APP_COUNT });
    history.push('/administration');
    dispatch({ type: SET_ADMIN_VIEW, payload: true });
  };

  const goToMyportal = () => {
    dispatch({ type: RESET_APP_COUNT });
    dispatch({ type: SET_ADMIN_VIEW, payload: false });
    if (isAuthenticated && checkLoggedInUserPermissions('load-card')) {
      history.push('/card/load-card');
    } else {
      history.push('/');
    }
  };

  const openUserSettingsHandler = () => {
    setOpenUserSettings(!openUserSettings);
  };

  const closeUserSettingsHandler = () => {
    setOpenUserSettings(!openUserSettings);
  };

  const onClickLoadCard = (link) => {
    dispatch({ type: RESET_APP_COUNT });
    localStorage.setItem('path', link);
  };

  return (
    <div className="fixed top-0 w-full bg-white z-30 shadow-lg text-gfDarkBlue font-ibmsans">
      <div className="mx-auto">
        <div className="flex items-center justify-between">
          <div className="flex items-center" style={{ width: '100%' }}>
            <div
              className="flex-shrink-0 cursor-pointer"
              onClick={goToMainPage}
            >
              <Logo />
            </div>

            <div className="mr-2 my-2 md:hidden w-full flex items-center justify-end">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setMobileMenu(true);
                }}
                type="button"
                className="bg-white rounded-md p-2 inline-flex items-center justify-center hover:text-red-500 hover:bg-gray-100 focus:outline-none"
              >
                <span className="sr-only">Open menu</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>

            <div
              className="grid grid-cols-1 w-100 pl-6 hidden md:block "
              style={{ width: '100%' }}
            >
              <div className="py-2 px-4">
                <ul className="list-reset md:flex lg:flex justify-end flex-1 items-center py-1">
                  {isAuthenticated && (
                    <li data-testid="shoppingCenterDropdownID" className="mr-3">
                      <TagListDropdown />
                    </li>
                  )}
                  {isAuthenticated &&
                    terminals &&
                    terminals.length !== 0 &&
                    !adminView && (
                      <li data-testid="terminalDropdownID" className="mr-3">
                        <div className="w-40">
                          <TerminalsDropdown
                            placeholder="Terminals"
                            options={terminals}
                            loading={loadingTerminals}
                          />
                        </div>
                      </li>
                    )}

                  <li
                    data-testid="languageDropdownID"
                    className="inline-block relative w-20 mr-3"
                  >
                    <LanguageDropdown />
                  </li>

                  {!isAuthenticated ? (
                    <li>
                      {isAuthenticated && (
                        <button
                          data-testid="signOutButtonID"
                          onClick={() => handleLogin()}
                          className="flex flex-row justify-center items-center bg-gfCoral hover:bg-opacity-75 text-gfDarkBlue rounded font-MulishBold py-2 px-3 focus:outline-none"
                          type="button"
                        >
                          {t('Sign-out')}
                        </button>
                      )}
                    </li>
                  ) : (
                    <div className="flex justify-center items-center pr-3">
                      {isAuthenticated && (
                        <div className="ml-1 relative" ref={wrapperRef}>
                          <button
                            data-testid="userNameWithIconButtonID"
                            onClick={(e) => {
                              e.preventDefault();
                              setIsOn(!isOn);
                            }}
                            className="max-w-xs flex items-center focus:outline-none border border-gfPeriwinkle rounded py-2 px-2 hover:bg-gfPeriwinkle"
                            id="user-menu"
                            aria-haspopup="true"
                          >
                            <span className="sr-only">Open user menu</span>
                            {/* <img alt="avatar" src={avatar} /> */}
                            <UserIcon />
                            {user && user.name && (
                              <span className="pl-2 text-base ">
                                {user.name}
                              </span>
                            )}
                          </button>

                          {isOn && (
                            <div
                              className="triangle origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-white text-gfDarkBlue ring-1 ring-black ring-opacity-5 z-10"
                              role="menu"
                              aria-orientation="vertical"
                              aria-labelledby="user-menu"
                            >
                              <div className="flex flex-col px-4 py-2 text-sm">
                                <span
                                  data-testid="signUserNameID"
                                  className="pl-2"
                                >
                                  {user.name}
                                </span>
                                <span
                                  data-testid="signUserEmailID"
                                  className="pl-2"
                                >
                                  {user.email}
                                </span>
                              </div>

                              {isAuthenticated && (
                                <div className="border-t border-gray-200 flex justify-between px-3 py-2">
                                  {/* <Link
                                                                        onClick={}
                                                                        className="bg-gfCoral hover:bg-opacity-75 text-gfDarkBlue rounded text-sm font-MulishBold py-2 px-3 focus:outline-none"
                                                                        to={`${window.location.hostname}/administration`}
                                                                        target="_blank"
                                                                    >
                                                                        Admin
                                                                    </Link> */}
                                  {!adminView && hasAdminPrivilege && (
                                    <button
                                      data-testid="adminButtonID"
                                      onClick={goToAdminPage}
                                      className="bg-gfCoral hover:bg-opacity-75 text-gfDarkBlue rounded text-sm font-MulishBold py-2 px-3 focus:outline-none"
                                    >
                                      Admin
                                    </button>
                                  )}
                                  {adminView && hasAdminPrivilege && (
                                    <button
                                      data-testid="myPortalButtonID"
                                      onClick={goToMyportal}
                                      className="bg-gfCoral hover:bg-opacity-75 text-gfDarkBlue rounded text-sm font-MulishBold py-2 px-3 focus:outline-none"
                                    >
                                      MyPortal
                                    </button>
                                  )}

                                  <button
                                    data-testid="signOutButtonID"
                                    onClick={() => handleLogout()}
                                    className="bg-gfCoral hover:bg-opacity-75 text-gfDarkBlue rounded text-sm font-MulishBold py-2 px-3 focus:outline-none"
                                    type="button"
                                  >
                                    {t('Sign-out')}
                                  </button>

                                  <Tooltip
                                    content={t('user-settings')}
                                    customCss={`white-space: nowrap; z-index: 1;`}
                                    zIndex={1}
                                    background="#FFF"
                                    border={GF_PERIWINKLE}
                                    color={GF_DARK_BLUE}
                                    fontFamily="inherit"
                                    radius={4}
                                    padding={10}
                                    placement="bottom"
                                  >
                                    <button
                                      data-testid="settingButtonID"
                                      className="hover:bg-opacity-75 text-gfDarkBlue rounded text-sm font-MulishBold py-2 px-3 focus:outline-none"
                                      onClick={openUserSettingsHandler}
                                    >
                                      <SettingIcon />
                                    </button>
                                  </Tooltip>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  <li className="inline-block relative mx-3 ">
                    <Tooltip
                      content={'Help'}
                      customCss={`
                                                                    white-space: nowrap;
                                                                    z-index: 1;
                                                                `}
                      zIndex={1}
                      background="#FFF"
                      border={GF_PERIWINKLE}
                      color={GF_DARK_BLUE}
                      fontFamily="inherit"
                      radius={4}
                      padding={10}
                      placement="bottom"
                    >
                      <Link
                        data-testid="helpButtonID"
                        to={{ pathname: 'https://support.giftify.me' }}
                        target="_blank"
                      >
                        <HelpIcon />
                      </Link>
                    </Tooltip>
                  </li>

                  {isAuthenticated && !adminView && (
                    <li
                      data-testid="notificationListButtonID"
                      className="inline-block relative  mx-3"
                    >
                      <Notification history={history} />
                    </li>
                  )}
                </ul>
              </div>

              {isAuthenticated && (
                <div className="py-3 px-4 border-t border-gfPeriwinkle">
                  <nav className="hidden md:flex md:space-x-5 lg:space-x-5 xl:space-x-10 2xl:space-x-10">
                    {navigation
                      .filter((menu) => hasPermissionParent(menu))
                      .map((menu, i) => {
                        if (menu.routes) {
                          return (
                            <DropdownComponent
                              key={`menu-${i}`}
                              hasPermissionParent={hasPermissionParent}
                              menuName={menu.name}
                              subMenus={menu.routes}
                              user={user}
                            />
                          );
                        }
                        return (
                          <Link
                            onClick={() => {
                              onClickLoadCard(menu.path);
                            }}
                            key={`menu-${i}`}
                            className={`font-medium hover:text-gfLightBlue`}
                            to={`${menu.path}?lan=${selectedLanguage.value}`}
                          >
                            {menu.name}
                          </Link>
                        );
                      })}
                    {/* <Link to={{ pathname: "https://support.giftify.me" }} target="_blank" className={`font-medium hover:text-gfLightBlue`}>
                                            Help
                                        </Link> */}
                  </nav>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {mobileMenu && isAuthenticated && (
        <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-40 ">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-y-2 divide-gray-50 bg-white overflow-auto h-screen">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <Logo />
                </div>
                <div className="-mr-2">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setMobileMenu(false);
                    }}
                    type="button"
                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  >
                    <span className="sr-only">Close menu</span>
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="mt-6">
                <div>
                  <TagListDropdown />
                </div>
                {terminals && !!terminals.length && (
                  <div className="mt-2">
                    <TerminalsDropdown
                      placeholder="Terminals"
                      options={terminals}
                      loading={loadingTerminals}
                    />
                  </div>
                )}

                <div className="mt-2 border-b pb-4">
                  <LanguageDropdown />
                </div>

                {isAuthenticated && (
                  <nav className="grid gap-y-4 mt-10  text-gfDarkBlue">
                    {navigation
                      .filter((menu) => hasPermissionParent(menu))
                      .map((menu, i) => {
                        if (menu.routes) {
                          return (
                            <DropdownComponent
                              key={`menu-${i}`}
                              menuName={menu.name}
                              subMenus={menu.routes}
                              user={user}
                            />
                          );
                        }
                        return (
                          <Link
                            onClick={() => {
                              onClickLoadCard(menu.path);
                            }}
                            key={`menu-${i}`}
                            className={`font-medium hover:text-gfLightBlue`}
                            to={`${menu.path}?lan=${selectedLanguage.value}`}
                          >
                            {menu.name}
                          </Link>
                        );
                      })}
                  </nav>
                )}

                <div className="flex flex-col justify-start py-5">
                  <Tooltip
                    content={'Help'}
                    customCss={`
                                                                    white-space: nowrap;
                                                                    z-index: 1;
                                                                `}
                    zIndex={1}
                    background="#FFF"
                    border={GF_PERIWINKLE}
                    color={GF_DARK_BLUE}
                    fontFamily="inherit"
                    radius={4}
                    padding={10}
                    placement="bottom"
                  >
                    <Link
                      to={{ pathname: 'https://support.giftify.me' }}
                      target="_blank"
                    >
                      <HelpIcon />
                    </Link>
                  </Tooltip>

                  {isAuthenticated && !adminView && (
                    <div className="py-4">
                      <Notification history={history} />
                    </div>
                  )}
                </div>

                <div className="flex justify-center items-center my-5">
                  {isAuthenticated && (
                    <div className="ml-1 relative" ref={wrapperRef}>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setIsOn(!isOn);
                        }}
                        className="max-w-xs flex items-center focus:outline-none focus:border-giftifyDarkPurple"
                        id="user-menu"
                        aria-haspopup="true"
                      >
                        <span className="sr-only">Open user menu</span>
                        {/* <img alt="avatar" src={avatar} /> */}
                        <UserIcon />
                        <span className="pl-2">{user.name}</span>
                      </button>

                      {isOn && (
                        <div
                          className="triangle origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-white text-gfDarkBlue ring-1 ring-black ring-opacity-5 z-10"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="user-menu"
                        >
                          <div className="flex flex-col px-4 py-2 text-sm">
                            <span className="pl-2">{user.name}</span>
                            <span className="pl-2">{user.email}</span>
                          </div>

                          <div className="border-t border-gray-200 flex justify-between px-3 py-2">
                            {!adminView && hasAdminPrivilege && (
                              <button
                                onClick={goToAdminPage}
                                className="bg-gfCoral hover:bg-opacity-75 text-gfDarkBlue rounded text-sm font-MulishBold py-2 px-3 focus:outline-none"
                              >
                                Admin
                              </button>
                            )}
                            {adminView && hasAdminPrivilege && (
                              <button
                                onClick={goToMyportal}
                                className="bg-gfCoral hover:bg-opacity-75 text-gfDarkBlue rounded text-sm font-MulishBold py-2 px-3 focus:outline-none"
                              >
                                MyPortal
                              </button>
                            )}

                            <button
                              onClick={() => handleLogin()}
                              className="bg-gfCoral hover:bg-opacity-75 text-gfDarkBlue rounded text-sm font-MulishBold py-2 px-3 focus:outline-none"
                              type="button"
                            >
                              {t('Sign-out')}
                            </button>
                            <Tooltip
                              content={t('user-settings')}
                              customCss={`white-space: nowrap; z-index: 1;`}
                              zIndex={1}
                              background="#FFF"
                              border={GF_PERIWINKLE}
                              color={GF_DARK_BLUE}
                              fontFamily="inherit"
                              radius={4}
                              padding={10}
                              placement="bottom"
                            >
                              <button
                                className="hover:bg-opacity-75 text-gfDarkBlue rounded text-sm font-MulishBold py-2 px-3 focus:outline-none"
                                onClick={openUserSettingsHandler}
                              >
                                <SettingIcon />
                              </button>
                            </Tooltip>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <UserSettings
        isOpen={openUserSettings}
        closeHandler={closeUserSettingsHandler}
      />
    </div>
  );
}
