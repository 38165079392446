import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import TabItemContainer from '../../../UIComponents/TabItemContainer';
import Table from '../../../Table';
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Form,
} from '../../../Form';
import DatePickerField from '../../../Input/DatePickerField';
import { cn } from '../../../../utils';
import Button from '../../../UIComponents/Button';
import useApiHook from '../../../../hooks/useAPI';
import reportAPI from '../../../../api/reportAPI';
import { checkLoggedInUserPermissions } from '../../../../utils/Helper';

function ReportsList({ month, monthNum, chooseYear }) {
  const { t } = useTranslation();

  const [purchaserExportHandler, getPurchaserExport] = useApiHook(
    reportAPI.getExcelReportPurchaser,
  );

  const [recipientExportHandler, getRecipientExport] = useApiHook(
    reportAPI.getExcelReportRecipient,
  );

  const filter = {
    chooseYear: chooseYear.getFullYear(),
    monthNum,
  };

  const isPurchaserEnable = useMemo(
    () => checkLoggedInUserPermissions('purchaser-report'),
    [],
  );
  const isRecipientEnable = useMemo(
    () => checkLoggedInUserPermissions('recipient-report'),
    [],
  );

  return (
    <Table.Row>
      <Table.Cell>
        <div className="text-gfLightBlue">{t(month)}</div>
      </Table.Cell>
      {isPurchaserEnable && (
        <Table.Cell>
          <div>
            <Button
              onClick={() => getPurchaserExport(filter)}
              label={t('Download')}
              classAddsName="text-center mx-auto"
              loading={purchaserExportHandler.loading}
            />
          </div>
        </Table.Cell>
      )}
      {isRecipientEnable && (
        <Table.Cell>
          <div>
            <Button
              onClick={() => getRecipientExport(filter)}
              label={t('Download')}
              classAddsName="text-center mx-auto"
              loading={recipientExportHandler.loading}
            />
          </div>
        </Table.Cell>
      )}
    </Table.Row>
  );
}

export default function PurchaserRecipient() {
  const { t } = useTranslation();
  const [chooseYear, setChooseYear] = useState(new Date());
  const methods = useForm({
    shouldUnregister: false,
    mode: 'onChange',
    defaultValues: {
      fromDate: new Date(),
      toDate: null,
      channel: null,
    },
  });

  const currentDate = new Date();
  const handleSubmit = () => {
    // Refresh list
  };
  const fromDatePickHandler = (date) => {
    setChooseYear(date);
  };

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return (
    <TabItemContainer
      title={t('reports.purchaser-recipient.report.title')}
      description={t('reports.purchaser-recipient.report.description')}
    >
      <Form {...methods}>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-5 items-end mb-4">
          <div className="flex flex-col">
            <FormField
              name="fromDate"
              render={(field) => (
                <FormItem>
                  <FormControl>
                    <DatePickerField
                      {...field}
                      dateFormat="yyyy"
                      label={t('Year')}
                      maxDate={currentDate}
                      showYearPicker
                      selected={chooseYear}
                      onChange={(date) => fromDatePickHandler(date)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <Button
            label={t('Go')}
            onClick={handleSubmit}
            classAddsName={cn(
              'h-10 border flex flex-row items-center justify-center rounded hover:bg-opacity-75 font-MulishBold bg-gfCoral px-4 focus:outline-none',
              methods.formState.isValid ? '' : 'opacity-50 pointer-events-none',
            )}
          />
        </div>
      </Form>
      <Table>
        <Table.Head>
          <tr>
            <Table.Header>{t('Month')}</Table.Header>
            {checkLoggedInUserPermissions('purchaser-report') && (
              <Table.Header>{t('Purchaser')}</Table.Header>
            )}
            {checkLoggedInUserPermissions('recipient-report') && (
              <Table.Header>{t('Recipient')}</Table.Header>
            )}
          </tr>
        </Table.Head>
        <Table.Body>
          {months.map(
            (month, index) =>
              (chooseYear.getFullYear() < currentDate.getFullYear() ||
                (chooseYear.getFullYear() <= currentDate.getFullYear() &&
                  index < currentDate.getMonth())) && (
                <ReportsList
                  month={month}
                  monthNum={index + 1}
                  chooseYear={chooseYear}
                  key={index}
                />
              ),
          )}
        </Table.Body>
      </Table>
    </TabItemContainer>
  );
}
