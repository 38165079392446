import { format, parseISO } from 'date-fns';
import { issuingAPI } from '../services/Axios';
import store from '../store/store';
import { exportExcelFile } from '../utils/Helper';

const getAllSales = async (params) => {
  const { selectedTag } = store.getState().tag;

  return issuingAPI.get(`tag/${selectedTag.id}/all-sales`, { params });
};

const getAllSalesSummary = async (params) => {
  const { selectedTag } = store.getState().tag;

  return issuingAPI.get(`tag/${selectedTag.id}/all-sales-summary/summary`, {
    params,
  });
};

const getExcelReportSales = (params) => {
  const { selectedTag } = store.getState().tag;
  const payload = {
    from_date: format(params.fromDate ?? new Date(), 'yyyy-MM-dd'),
    to_date: format(params.toDate ?? new Date(), 'yyyy-MM-dd'),
    tag: selectedTag.name,
    channel: params.channel?.id ?? null,
  };

  const headers = {
    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };

  return issuingAPI
    .get(`tag/${selectedTag.id}/all-sales/stream`, {
      responseType: 'blob',
      headers,
      params: payload,
    })
    .then((success) => {
      const reportName = 'SalesSummary-Sale';
      const fileName = `${reportName}-${params.fromDate}-${params.toDate}-${selectedTag.name}`;
      exportExcelFile(success.data, fileName);
    });
};

const getExcelReportAllSalesSummary = (params) => {
  const { selectedTag } = store.getState().tag;
  const payload = {
    from_date: format(parseISO(params.from_date) ?? new Date(), 'yyyy-MM-dd'),
    to_date: format(parseISO(params.to_date) ?? new Date(), 'yyyy-MM-dd'),
    tag: selectedTag.name,
    channel: params.channel?.id ?? null,
  };

  const headers = {
    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };

  return issuingAPI
    .get(`tag/${selectedTag.id}/all-sales-summary/summary/stream`, {
      responseType: 'blob',
      headers,
      params: payload,
    })
    .then((success) => {
      const reportName = 'SalesSummaryWeeklyBreakdown';
      const fileName = `${reportName}-${payload.from_date}-${payload.to_date}-${selectedTag.name}`;
      exportExcelFile(success.data, fileName);
    });
};

const getExcelReportAllSalesSummaryWeeklyBreakdown = (params) => {
  const { selectedTag } = store.getState().tag;
  const payload = {
    from_date: format(parseISO(params.from_date) ?? new Date(), 'yyyy-MM-dd'),
    to_date: format(parseISO(params.to_date) ?? new Date(), 'yyyy-MM-dd'),
    tag: selectedTag.name,
    channel: params.channel?.id ?? null,
  };

  const headers = {
    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };

  return issuingAPI
    .get(`tag/${selectedTag.id}/all-sales-summary/weekly-breakdown/stream`, {
      responseType: 'blob',
      headers,
      params: payload,
    })
    .then((success) => {
      const reportName = 'SalesSummaryWeeklyBreakdown';
      const fileName = `${reportName}-${payload.from_date}-${payload.to_date}-${selectedTag.name}`;
      exportExcelFile(success.data, fileName);
    });
};

const getAllSalesWeeklyBreakdownSummary = async (params) => {
  const { selectedTag } = store.getState().tag;

  return issuingAPI.get(
    `tag/${selectedTag.id}/all-sales-summary/weekly-breakdown`,
    {
      params,
    },
  );
};

const getMVCPaymentMethodsFullList = (params) => {
  const { tagID } = store.getState().tag;
  return issuingAPI.get(`tag/${tagID}/mvc-payment-method`, {
    params,
  });
};

const getExcelReportPurchaser = (params) => {
  const { selectedTag } = store.getState().tag;
  const payload = {
    month: params.monthNum,
    year: params.chooseYear,
  };

  const headers = {
    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };

  return issuingAPI
    .get(`tag/${selectedTag.id}/purchaser-report/stream`, {
      responseType: 'blob',
      headers,
      params: payload,
    })
    .then((success) => {
      const reportName = 'PurchaserMonthly';
      const fileName = `${reportName}-${payload.year}-${payload.month}-${selectedTag.name}`;
      exportExcelFile(success.data, fileName);
    });
};

const getExcelReportRecipient = (params) => {
  const { selectedTag } = store.getState().tag;
  const payload = {
    month: params.monthNum,
    year: params.chooseYear,
  };

  const headers = {
    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };

  return issuingAPI
    .get(`tag/${selectedTag.id}/recipient-report/stream`, {
      responseType: 'blob',
      headers,
      params: payload,
    })
    .then((success) => {
      const reportName = 'RecipientMonthly';
      const fileName = `${reportName}-${payload.year}-${payload.month}-${selectedTag.name}`;
      exportExcelFile(success.data, fileName);
    });
};

export default {
  getAllSales,
  getAllSalesSummary,
  getExcelReportSales,
  getAllSalesWeeklyBreakdownSummary,
  getExcelReportAllSalesSummary,
  getExcelReportAllSalesSummaryWeeklyBreakdown,
  getMVCPaymentMethodsFullList,
  getExcelReportPurchaser,
  getExcelReportRecipient,
};
